<template>
  <div class="position">
    <span>当前位置：</span>
    <router-link
      v-for="(item, index) in route.slice(route.length - 2, route.length)"
      :key="index"
      :data-index="index"
      :to="item.path"
    >
      {{ item.meta.title ? ``+ item.meta.title+` > ` : "" }}
    </router-link>
  </div>
</template>


<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
export default {
  name: "CurrentLocation",
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      route: proxy.$router.currentRoute.value.matched,
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
</style>
