<template>
  <div class="main clearfix">
    <CurrentLocation />
    <!-- <div class="ct2-sd">
      <PanelNav :title="title" :navs="navs" />
      </div> -->
    <div class="box">
      <div class="news-wrap">
      <div class="news-title">
        <h1 v-html="title"></h1>
      </div>
      <div class="news-content" v-html="content"></div>
    </div>
    </div>
  </div>
</template>

<script>
import CurrentLocation from "./components/CurrentLocation";
import { toRefs } from "vue";

export default {
  name: "DetailsContainer",
  props: ["title", "content"],
  components: { CurrentLocation },
  created() {},
  setup(props) {
    return { ...toRefs(props) };
  },
  methods: {},
};
</script>

<style scoped>
</style>